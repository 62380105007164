 .center-cropped {
    width: 100%;
    height: 300px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
  }
  
  /* Set the image to fill its parent and make transparent */
  .center-cropped img {
    min-height: auto;
    min-width: auto;
    /* IE 8 */
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    /* IE 5-7 */
    filter: alpha(opacity=0);
    /* modern browsers */
    opacity: 0;
  }

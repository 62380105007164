.nav-link:hover{
    text-decoration: underline;
    color: #000;
}

.nav-link{
    color: #000;
    border-radius: 16px;
}

.navbar{
    /*   z-index should be above .close-navbar-toggler */
      z-index:2;
    }
    .close-navbar-toggler{
      position:absolute;
      top:0;
      left:0;
      height:100%;
      width:100%;
      z-index:1;
      cursor:pointer;
    }
    .close-navbar-toggler.collapsed{
      z-index:-1;
    }
.article {
  padding: 16px;
}

.ImageContainer{
    /* Set a specific height */
    min-height: 60vh;
    /* Create the parallax scrolling effect */
    background-position: center 56px;
    background-repeat: no-repeat;
    background-size: cover;
}

/* Voor kleinere schermen kunnen we parallax uitschakelen en de achtergrond aanpassen */
@media screen and (max-width: 768px) {
    .ImageContainer {
        background-attachment: inherit !important;
        /* background-size: contain; /* Laat de volledige afbeelding zien zonder bijsnijden */
        /* background-attachment: scroll; /* Achtergrond scrolt mee op kleinere schermen */
        /* height: auto; /* Zorgt ervoor dat de pagina niet geforceerd wordt uitgerekt */
    }
}
/* Zelfde voor grotere schermen, als je consistentie wilt behouden */
@media screen and (min-width: 320px) {
    .ImageContainer {
        background-size: contain; /* Laat de hele afbeelding zien zonder bijsnijden
        /* height: auto; /* Zorgt ervoor dat de pagina niet geforceerd wordt uitgerekt */
        /* background-attachment: inherit !important; */
    }
}
/* Voor tablets en grotere schermen blijft het hetzelfde */
@media screen and (min-width:480px){
    .ImageContainer{
        background-size: contain; /* Laat de hele afbeelding zien zonder bijsnijden
        /* height: auto; /* Zorgt ervoor dat de pagina niet geforceerd wordt uitgerekt */
        /* background-attachment: inherit !important; */
    }
}
@media screen and (min-width:600px){
    .ImageContainer{
        background-size: contain; /* Laat de hele afbeelding zien zonder bijsnijden
        /* height: auto; /* Zorgt ervoor dat de pagina niet geforceerd wordt uitgerekt */
        /* background-attachment: inherit !important; */
    }
}
@media screen and (min-width:801px){
    .ImageContainer{
        background-size: contain; /* Laat de hele afbeelding zien zonder bijsnijden
        /* height: auto; /* Zorgt ervoor dat de pagina niet geforceerd wordt uitgerekt */
        /* background-attachment: inherit !important; */
    }
}

@media screen and (min-width:1025px){
    .ImageContainer{
        background-size: contain; /* Laat de hele afbeelding zien zonder bijsnijden
        /* height: auto; /* Zorgt ervoor dat de pagina niet geforceerd wordt uitgerekt */
        /* background-attachment: inherit !important; */
    }
}
@media screen and (min-width:1281px){
    .ImageContainer{
        background-size: contain; /* Laat de hele afbeelding zien zonder bijsnijden
        /* height: auto; /* Zorgt ervoor dat de pagina niet geforceerd wordt uitgerekt */
        /* background-attachment: inherit !important; */
    }
}



iframe{
    height: 40vh;
    width: 100%;
}

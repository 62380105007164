.loginform{
  margin: auto;
  width: 100%;
  padding: 100px 20px 0px 20px;
}

h1{
  font-size: 50px;
}


section{
    background-color: #f8f9fa;
    padding-bottom: 10px;
}


body {
    margin: 0;
    font-family: "Alegreya Sans SC", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  

@media only screen and (min-width: 1024px) {
  .loginform{
    margin: auto;
    width: 500px;
    padding: 100px 20px 0px 20px;
  }
}
.contactImgage{
  display: inline-block;
  width: 100%;
  height: 100%;
  object-fit: scale-down;
  object-position: center;
}

 /* Style all font awesome icons */
 .fa {
    color: black;
    padding: 20px;
    font-size: 30px;
    width: 50px;
    text-align: center;
    text-decoration: none;
  }
  
  /* Add a hover effect if you want */
  .fa:hover {
    color: gray;
  }

  #mail{
    color: black;
    text-decoration: none;
  }

  #mail:hover{
    color: grey;
    text-decoration: none;
  }
